import { createApp } from 'vue'
import App from '@/app.vue'
import router from '@/router'
import store from '@/store'
import '@/router/permission'

import 'normalize.css'
import '@/assets/style/index.scss'
import 'vant/lib/index.css'

createApp(App).use(store).use(router).mount('#app')
