/**
 * 封装 axios
 */
import axios from 'axios'
import { showFailToast } from 'vant'

const config = {
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5 * 1000, // 请求超时时间设置
  crossDomain: true,
  // withCredentials: true, // Check cross-site Access-Control
  // 定义可获得的http响应状态码
  // return true、设置为null或者undefined，promise将resolved,否则将rejected
  validateStatus(status) {
    return status >= 200 && status < 510
  }
}

// 创建请求实例
const _axios = axios.create(config)

// 请求拦截器
_axios.interceptors.request.use(
  originConfig => {
    const reqConfig = { ...originConfig }

    if (!reqConfig.url) {
      showFailToast('request need url')
    }

    reqConfig.method = reqConfig.method.toLowerCase() // 大小写容错

    // 参数容错
    if (reqConfig.method === 'get') {
      if (!reqConfig.params) {
        reqConfig.params = reqConfig.data || {}
      }
    } else if (reqConfig.method === 'post') {
      if (!reqConfig.data) {
        reqConfig.data = reqConfig.params || {}
      }

      // 检测是否包含文件类型, 若包含则进行 formData 封装
      let hasFile = false
      Object.keys(reqConfig.data).forEach(key => {
        if (typeof reqConfig.data[key] === 'object') {
          const item = reqConfig.data[key]
          if (item instanceof FileList || item instanceof File || item instanceof Blob) {
            hasFile = true
          }
        }
      })

      // 检测到存在文件使用 FormData 提交数据
      if (hasFile) {
        const formData = new FormData()
        Object.keys(reqConfig.data).forEach(key => {
          formData.append(key, reqConfig.data[key])
        })
        reqConfig.data = formData
      }
    }

    return reqConfig
  },
  error => Promise.reject(error)
)

// 响应拦截器
_axios.interceptors.response.use(
  async res => {
    if (res.status.toString().charAt(0) === '2' && res.data.code === 0) {
      return res.data.data
    }

    const { msg: message } = res.data

    return new Promise((resolve, reject) => {
      let tipMessage = ''

      if (typeof message === 'string') {
        tipMessage = message
      }
      if (Object.prototype.toString.call(message) === '[object Object]') {
        ;[tipMessage] = Object.values(message).flat()
      }
      if (Object.prototype.toString.call(message) === '[object Array]') {
        ;[tipMessage] = message
      }
      showFailToast(tipMessage)
      reject(res)
    })
  },
  error => {
    if (!error.response) {
      showFailToast('请检查 API 是否异常')
      console.error('error', error)
    }

    // 判断请求超时
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      showFailToast('请求超时')
    }
    return Promise.reject(error)
  }
)

// 导出常用函数

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function post(url, data = {}, params = {}) {
  return _axios({
    method: 'post',
    url,
    data,
    params,
    // new
    onUploadProgress: progressEvent => {
      // console.log(progressEvent)
    }
  })
}

/**
 * @param {string} url
 * @param {object} params
 */
export function get(url, params = {}, responseType = '') {
  return _axios({
    method: 'get',
    url,
    params,
    // new
    responseType
  })
}

/**
 * @param {string} url
 * @param {object} data
 * @param {object} params
 */
export function put(url, data = {}, params = {}) {
  return _axios({
    method: 'put',
    url,
    params,
    data
  })
}

/**
 * @param {string} url
 * @param {object} params
 */
export function _delete(url, params = {}) {
  return _axios({
    method: 'delete',
    url,
    params
  })
}

export default _axios
