import { cloneDeep, throttle, debounce } from 'lodash'

const Utils = {}

/**
 * 删除当前URL参数
 * @param {String | Array} params
 * @returns {String}
 */
Utils.delLocalUrlParam = params => {
  const local = window.location
  if (local.search) {
    if (typeof params === 'string') {
      params = [params]
    }
    const querys = local.search.substr(1).split('&')
    const baseUrl = local.origin + local.pathname + '?'
    return baseUrl + querys.filter(query => !params.includes(query.split('=')[0])).join('&')
  }
  return window.location.href
}

/**
 * 删除指定URL参数
 * @param {String} url
 * @param {String | Array} params
 * @returns {String}
 */
Utils.delRemoteUrlParam = (url, params) => {
  const urls = url.split('?')
  if (urls.length > 1) {
    if (typeof params === 'string') {
      params = [params]
    }
    const querys = urls[1].split('&')
    const baseUrl = urls[0] + '?'
    return baseUrl + querys.filter(query => !params.includes(query.split('=')[0])).join('&')
  }
  return url
}

/**
 * 防抖函数
 * @param {*} func 函数体
 * @param {*} wait 延时
 */
Utils.debounce = (func, wait = 50) => debounce(func, wait)

/**
 * 节流函数
 * @param {*} func 函数体
 * @param {*} wait 延时
 */
Utils.throttle = (func, wait = 50) => throttle(func, wait)

/**
 * 深度遍历，深拷贝
 * @param {*} data
 */
Utils.deepClone = data => cloneDeep(data)

export default Utils
