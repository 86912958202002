import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/register/register'
  },
  {
    path: '/show',
    name: 'show',
    component: null,
    children: [
      // {
      //   path: 'home',
      //   name: 'ShowHome',
      //   meta: { title: '名师帮' },
      //   component: () => import('@/views/show/home')
      // },
      {
        path: 'index',
        name: 'ShowIndex',
        meta: { title: '名师帮' },
        component: () => import('@/views/show/index')
      },
      {
        path: 'view',
        name: 'ShowView',
        meta: { title: '名师帮' },
        component: () => import('@/views/show/view')
      }
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: null,
    children: [
      {
        path: 'accept-coupon',
        name: 'AcceptCoupon',
        meta: { title: '立即领取' },
        component: () => import('@/views/register/accept-coupon')
      },
      {
        path: 'accept-coupon-success',
        name: 'AcceptCouponSuccess',
        meta: { title: '领取成功' },
        component: () => import('@/views/register/accept-coupon-success')
      },
      {
        path: 'accept-packet',
        name: 'AcceptPacket',
        meta: { title: '立即领取' },
        component: () => import('@/views/register/accept-packet')
      },
      {
        path: 'accept-packet-success',
        name: 'AcceptPacketSuccess',
        meta: { title: '领取成功' },
        component: () => import('@/views/register/accept-packet-success')
      },
      {
        path: 'join-group',
        name: 'JoinGroup',
        meta: { title: '加入班级' },
        component: () => import('@/views/register/join-group')
      },
      {
        path: 'join-group-success',
        name: 'JoinGroupSuccess',
        meta: { title: '加入班级成功' },
        component: () => import('@/views/register/join-group-success')
      },
      {
        path: 'register',
        name: 'Register',
        meta: { title: '注册' },
        component: () => import('@/views/register/register')
      },
      {
        path: 'register-success',
        name: 'RegisterSuccess',
        meta: { title: '注册成功' },
        component: () => import('@/views/register/register-success')
      }
    ]
  },
  {
    path: '/help',
    name: 'help',
    component: null,
    children: [
      {
        path: 'agreement',
        name: 'Agreement',
        meta: { title: '用户协议' },
        component: () => import('@/views/help/agreement')
      },
      {
        path: 'privacy',
        name: 'Privacy',
        meta: { title: '隐私政策' },
        component: () => import('@/views/help/privacy')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
