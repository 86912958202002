/* eslint-disable class-methods-use-this */
import wx from 'weixin-js-sdk'
import { get } from '@/utils/axios'

class Wechat {
  getOpenId(params) {
    return get('wechat-mp/open-id', params)
  }

  getJsSign(url) {
    return get('wechat-mp/js-sign', { url })
  }

  async setWx(title, desc) {
    const url = location.href
    const { info } = await this.getJsSign(url)

    wx.config({
      debug: false,
      appId: info.appId,
      timestamp: info.timestamp,
      nonceStr: info.nonceStr,
      signature: info.signature,
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData']
    })

    wx.ready(() => {
      const link = url
      const imgUrl = 'https://mingshibang-1259438581.cos.ap-chongqing.myqcloud.com/material/logo.png'
      // 分享给朋友及分享到QQ
      wx.updateAppMessageShareData({
        title,
        desc,
        link,
        imgUrl
      })
      // 分享到朋友圈及分享到QQ空间
      wx.updateTimelineShareData({
        title,
        link,
        imgUrl
      })
    })
  }
}

export default new Wechat()
