import store from 'store'
import engine from 'store/src/store-engine'
import sessionStorage from 'store/storages/sessionStorage'

const STORE_PREFIX = 'msb_wx:'

/**
 * localStorage
 */
const storeLocal = {}

storeLocal.get = key => store.get(STORE_PREFIX + key)

storeLocal.set = (key, val) => {
  store.set(STORE_PREFIX + key, val)
}

storeLocal.remove = key => {
  store.remove(STORE_PREFIX + key)
}

/**
 * sessionStorage
 */
const storeSession = {}
const sessionStore = engine.createStore([sessionStorage], [])

storeSession.get = key => sessionStore.get(STORE_PREFIX + key)

storeSession.set = (key, val) => {
  sessionStore.set(STORE_PREFIX + key, val)
}

storeSession.remove = key => {
  sessionStore.remove(STORE_PREFIX + key)
}

export { storeLocal, storeSession }
