import router from '.'
import Utils from '@/utils/util'
import Wechat from '@/model/wechat'
import { storeLocal } from '@/utils/store'

const whiteList = ['/help/agreement', '/help/privacy', '/show/home'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }

  const codePara = to.query.code
  const code = Array.isArray(codePara) ? codePara[codePara.length - 1] : codePara
  const openId = storeLocal.get('open_id')
  if (!code && !openId && !whiteList.includes(to.path)) {
    const wxBaseUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize'
    const appId = 'wx0376198a26eda575'
    const redirectUri = encodeURIComponent(location.href) // document.URL || location.href
    const responseType = 'code'
    const scope = 'snsapi_userinfo' // snsapi_base || snsapi_userinfo
    const state = Math.random().toString(36).substr(2)
    const queryUrl = `${wxBaseUrl}?appid=${appId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&state=${state}&connect_redirect=1#wechat_redirect`
    location.replace(queryUrl)
  } else {
    if (code) {
      const fromId = to.query.from_id ? to.query.from_id : 0
      const params = { code: code, fromId: fromId }
      const { info } = await Wechat.getOpenId(params)
      storeLocal.set('open_id', info.openKey)
      location.replace(Utils.delLocalUrlParam(['code', 'state']))
    }
    next()
  }
})
